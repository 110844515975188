import {
  Box,
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import TagMap from '@components/tags/TagMap';
import ResponsiveImage from '@components/ResponsiveImage';
import { getAddressCountry } from '@utils';
import LiquidatedTag from '@components/LiquidatedTag';
import NextLink from 'next/link';

const Container = styled('div')(({ theme }) => ({
  '& > div:first-of-type': {
    marginBottom: theme.spacing(3),
  },
  '& .wide-only': {
    display: 'none',
  },
  '@container (min-width: 600px)': {
    display: 'grid',
    gridTemplateColumns: '1fr 280px',
    gridColumnGap: theme.spacing(2),
    '& > div:first-of-type': {
      marginBottom: 0,
    },
    '& .wide-only': {
      display: 'block',
    },
  },
}));

export default function CompactOrganizationItem(props) {
  const {
    item, tagItems, onClick,
  } = props;
  const { t } = useTranslation();

  const cellStyle = {
    verticalAlign: 'top', p: 0, whiteSpace: 'nowrap', border: 'none',
  };
  const cellValueStyle = {
    p: 0, border: 'none',
  };

  const handleClick = (id) => (e) => {
    e.preventDefault();

    if (onClick) {
      onClick(id);

      /* if (item.addressLatitude
        && item.addressLongitude
      ) {
        window.dispatchEvent(new CustomEvent('b:map:zoom-to-coords', {
          detail: {
            lat: item.addressLatitude,
            lng: item.addressLongitude,
            zoom: 12,
          },
        }));
      } */
    }
  };

  return (
    <TableRow
      hover
      onClick={handleClick(item.id)}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell>
        <Container>
          <div>
            {item.title.map(({ id, name, isLiquidated }, index) => (
              <NextLink key={id} href={`/organizations/${id}`} legacyBehavior passHref>
                <Link
                  color={isLiquidated ? 'primary.main' : 'text.primary'}
                  component="a"
                  gutterBottom
                  onClick={handleClick(item.id)}
                  sx={index < item.title.length - 1
                    ? {
                      display: 'block',
                      fontWeight: 'normal',
                    }
                    : {
                      display: 'block',
                    }}
                  variant="h6"
                >
                  {name}
                  {isLiquidated && (<LiquidatedTag sx={{ ml: 1 }} />)}
                </Link>
              </NextLink>
            ))}
            <Box mt={1}>
              <TagMap
                entityTags={item.tags}
                hideTagRootTitle
                leaveTypographyProps={{ variant: 'caption' }}
                renderColors
                tagItems={tagItems}
              />
            </Box>
          </div>
          <div>
            <div className="wide-only">
              {item.logos.slice(0, 2).map((logo) => (
                <ResponsiveImage
                  key={logo.url}
                  mb={2}
                  src={logo.url}
                />
              ))}
            </div>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{
                      ...cellStyle, width: '1px', pr: 1, pb: 1,
                    }}
                  >
                    <Typography component="div" variant="caption">
                      {t('modules.organizations.components.CompactOrganizationItem.abbreviation')}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...cellValueStyle, pb: 1,
                    }}
                  >
                    <Box alignItems="center" display="flex" flexWrap="wrap">
                      {item.abbreviations.map(({ abbreviation, isLiquidated }, index) => (
                        <Fragment key={abbreviation}>
                          <Typography color={isLiquidated ? 'primary.main' : null} sx={{ fontWeight: 'bold' }} variant="caption">
                            {abbreviation}
                            {isLiquidated && (<LiquidatedTag sx={{ ml: 1 }} />)}
                          </Typography>
                          {index < item.abbreviations.length - 1 && (
                            <Typography sx={{ fontWeight: 'bold', mx: 1 }} variant="caption">
                              -
                            </Typography>
                          )}
                        </Fragment>
                      ))}
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      ...cellStyle, width: '1px', pr: 1, pb: 1,
                    }}
                  >
                    <Typography component="div" variant="caption">
                      {t('modules.organizations.components.CompactOrganizationItem.location')}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...cellValueStyle, pb: 1,
                    }}
                  >
                    {(item.location.zip
                      || item.location.city
                      || item.location.state
                      || item.location.country) ? (
                        <>
                          <Typography component="div" sx={{ fontWeight: 'bold' }} variant="caption">
                            {item.location.zip}
                            {' '}
                            {item.location.city}
                          </Typography>
                          <Typography component="div" sx={{ fontWeight: 'bold' }} variant="caption">
                            {item.location.state}
                          </Typography>
                          <Typography component="div" sx={{ fontWeight: 'bold' }} variant="caption">
                            {getAddressCountry(item)}
                          </Typography>
                        </>
                      ) : (
                        <Typography component="div" sx={{ fontWeight: 'bold' }} variant="caption">
                          k. A.
                        </Typography>
                      )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {item.category && (
              <Box>
                <Chip
                  label={t(`enums.organizationCategory.${item.category}`)}
                  size="small"
                />
              </Box>
            )}
          </div>
        </Container>
      </TableCell>
    </TableRow>
  );
}
