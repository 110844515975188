import { Box } from '@mui/material';

export default function ListContainer({ children }) {
  return (
    <Box
      p={2}
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </Box>
  );
}
