export default function MapContainer({ children }) {
  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    }}
    >
      {children}
    </div>
  );
}
