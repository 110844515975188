/* eslint-disable import/no-extraneous-dependencies */
import SplitPane from '@components/split-pane';

export default function SplitView({ left, right }) {
  const handleResize = () => {
    window.dispatchEvent(new CustomEvent('b:splitpane:resize'));
  };
  return (
    <SplitPane
      defaultSize="50%"
      maxSize={-300}
      minSize={500}
      onChange={handleResize}
      paneStyle={{
        display: 'flex',
        flexDirection: 'column',
      }}
      split="vertical"
    >
      {left}
      {right}
    </SplitPane>
  );
}
