import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import TablePagination from '@components/TablePagination';
import CompactOrganizationItem from '@modules/organizations/components/CompactOrganizationItem';

export default function CompactOrganizationList(props) {
  const {
    loading,
    onItemClick,
    organizations,
    tagItems,
  } = props;
  const containerRef = useRef();
  const paginationRef = useRef();
  const [tableHeight, setTableHeight] = useState(500);
  const { t } = useTranslation();

  useLayoutEffect(() => {
    function handleResize() {
      let height = containerRef.current.offsetHeight;
      height -= paginationRef.current.offsetHeight;
      setTableHeight(height);
    }

    const timeout = setTimeout(handleResize, 250);
    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Paper
      ref={containerRef}
      elevation={5}
      sx={{
        // Css container size reference
        containerType: 'inline-size',
        flex: 1,
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <TableContainer sx={{ height: '100%', maxHeight: tableHeight }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                {t('modules.organizations.components.CompactOrganizationList.columns.organization')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && [...Array(10).keys()].map((index) => (
              <TableRow key={index}>
                <TableCell sx={{ opacity: 1 / (index + 1) }}>
                  <Skeleton />
                </TableCell>
              </TableRow>
            ))}
            {!loading && organizations.length === 0 && (
              <TableRow>
                <TableCell sx={{ py: 2 }}>
                  <Typography color="text.disabled" variant="body2">
                    {t('modules.organizations.components.CompactOrganizationList.noData')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!loading && organizations.map((item) => (
              <CompactOrganizationItem
                key={item.id}
                item={item}
                onClick={onItemClick}
                tagItems={tagItems}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div ref={paginationRef}>
        <TablePagination {...props} />
      </div>
    </Paper>
  );
}
